import React, {useState, useCallback,useRef} from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash,faImage, faKeyboard, faPrint} from '@fortawesome/free-solid-svg-icons';
import './App.css';
import logo from './MorLogo.jpg';

const SQUARE_SIZE = "160px";//A4
const FONT_SIZE = "20pt";//A4
//for testing img URLs:
//https://www.publicdomainpictures.net/pictures/40000/nahled/basic-triangle-outline.jpg
const Face = props =>{
  const [hover, setHover] = useState(false);
  const [imgURL, setImgURL] = useState("");
  const inputText = useRef(null);
  const inputImg = useRef(null);


  const focusOnText = useCallback(e=>{
    inputText.current.focus();
  },[inputText]);


  const setFile = useCallback(f=>{
    if(f)
       setImgURL(URL.createObjectURL(f));
      else {
        setImgURL(undefined);
      }
  },[setImgURL]);

  const deleteContent = useCallback(e=>{
      inputText.current.value = null;
      setFile(null);
  },[inputText, setFile]);

  const clickFile = useCallback(e=>{
      inputImg.current.click();
    },[inputImg]);




  return(<Square onMouseEnter = {()=>{setHover(true)}}
                 onClick = {()=>{setHover(true)}}
                 onMouseLeave = {()=>{setHover(false)}}
                 style={props}>
            <Image src={imgURL}/>
            <RowIcons show={hover}>
              <InputFile type="file" ref={inputImg} onChange={event => {setFile(event.target.files[0])}} hidden/>
              <IconWrapper>
                <FontAwesomeIcon icon={faImage} onClick={clickFile}/>
              </IconWrapper>
              <IconWrapper>
                <FontAwesomeIcon icon={faTrash} onClick={deleteContent}/>
              </IconWrapper>
              <IconWrapper>
                <FontAwesomeIcon icon={faKeyboard} onClick={focusOnText}/>
              </IconWrapper>
            </RowIcons>

            <Input hovered={hover}
                   ref={inputText}/>
          </Square>);
}

const App = () => {

  return (
    <Container>
      <Title defaultValue="הקוביה שלי"/>
      <a href="https://funlearning.co.il/" target="_blank">
        <img src={logo} alt="logo" height="100px" width="100px" left="10px"/>
      </a>

      <Label>Click on a square to edit</Label>
      <Row>
        <IconWrapper>
          <FontAwesomeIcon icon={faPrint} onClick={()=>{window.print()}}/>
        </IconWrapper>
        <Label onClick={()=>{window.print()}}>Print</Label>
      </Row>
      <Dice className="App">
        <Row>
          <EmptySquare deg="0deg">
            <Trapezoid/>
          </EmptySquare>
          <Face index="0"  borderBottom ="1px solid rgba(0,0,0,0)"/>
          <EmptySquare deg="0deg">
            <Trapezoid/>
          </EmptySquare>
        </Row>
        <Row>
          <Face index="1"  borderRight = "1px solid rgba(0,0,0,0)"/>
          <Face index="2"/>
          <Face index="3"  borderLeft = "1px solid rgba(0,0,0,0)" />
        </Row>
        <Row>
          <EmptySquare deg="270deg">
            <Trapezoid/>
          </EmptySquare>
          <Face index="4"  borderTop ="1px solid rgba(0,0,0,0)"/>
          <EmptySquare deg="90deg">
            <Trapezoid/>
          </EmptySquare>
        </Row>
        <Row>
          <EmptySquare deg="270deg">
            <Trapezoid/>
          </EmptySquare>
          <Face index="5"  borderTop ="1px solid rgba(0,0,0,0)"/>
          <EmptySquare deg="90deg">
            <Trapezoid/>
          </EmptySquare>
        </Row>
      </Dice>
      <Credits>
        <a href="https://www.facebook.com/Funlearningmor/" target="_blank">https://www.facebook.com/Funlearningmor</a>
        <a href="https://www.linkedin.com/in/esther-friedman-93884731//" target="_blank">https://www.linkedin.com/in/esther-friedman-93884731/</a>
      </Credits>
    </Container>
  );
}



export default App;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  position: relative;

`;
const Credits = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 10pt;
  padding: 20px;
  @media print{
    position:fixed;
    bottom:0;
    left:0;
    width: 100%;
  }

`;
const Title =styled.input`
  font-size: 30px;
  font-wieight: bold;
  border: 0px;
  text-align: center;
  margin: 20px;
`;
const Label = styled.div`
  margin: 20px 10px;
  text-align: left;
  user-select: none;
  @media print {
    visibility: hidden;
    height: 0px;
 }
`;


const InputFile =styled.input`
  opacity: 1;

`;


const Input =styled.textarea`
  width: 95%;
  height: 60%;
  margin-top: 35%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
  padding: 5px;
  text-align: center;
  z-index: 1000;
  resize: none;
  font-family: 'Rubik', sans-serif;
  font-size: ${FONT_SIZE};
  font-weight: bold;

  ${({hovered}) => hovered && `background: rgba(242,242,242,0.5);`}

`;
//url(${({src}) => src}) no-repeat center;
const Image = styled.div`
	width: 95%;
	height:  95%;
	position: absolute;
	top: 2px;
	left: 2px;
	background: url(${({src}) => src}) no-repeat center;
  z-index:-1000;
	background-size: contain;
	${({isDone, isCorrect}) => isDone && !(isCorrect) &&
	`filter: grayscale(0.5);
	`};

 `;
//url(${({src}) => src}) no-repeat center;
const Dice = styled.div`
  display: flex;
  flex-direction: column;
  height: vh;

`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RowIcons = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  margin-top:5px;
  visibility: hidden;
  z-index: 1;
  ${({show}) => show &&
  `visibility: visible;`
  }
  @media print {
    visibility: hidden;
  }

`;

const IconWrapper = styled.div`
  cursor: pointer;
  &:hover {
    color: #5c5c5c;
  }
  @media print {
    visibility: hidden;
  }
`;

const Square = styled.div`
  position: relative;
	width: ${SQUARE_SIZE};
	height: ${SQUARE_SIZE};
  border: 1px solid #000000;
  opacity: 1;
  &:hover {
    background: rgba(242,242,242,0.5);
  }
`;

const Trapezoid =styled.div`
  border-bottom: 20px solid #e6e6e6;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  height: 0;
  width: 110px;
  position: absolute;
  bottom: 0;
}
  `;

//  transform: perspective(calc(${SQUARE_SIZE}/10)) rotateX(10deg);
//   const Trapezoid =styled.div`
//     width:calc(${SQUARE_SIZE}*0.9);
//     height: calc(${SQUARE_SIZE}/10);
//     transform: perspective(calc(${SQUARE_SIZE}/10)) rotateX(10deg);
//     bottom: 0;
//     right: calc(${SQUARE_SIZE}/20);
//     border: 1px solid black;
//     position: absolute;
//     @media print {
//     background: red;
//
// }
//   `;

const EmptySquare = styled.div`
  position: relative;
	width: ${SQUARE_SIZE};
	height: ${SQUARE_SIZE};

  ${({deg}) => deg &&
  `transform: rotate(${deg});`
  }
  border: 1px solid rgba(0,0,0,0);
`;
